import React, { useState, useEffect } from "react"
import Icon from "./core/Icon"

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    // Show the button when the user scrolls down by a certain amount
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener("scroll", toggleVisibility)

    return () => {
      window.removeEventListener("scroll", toggleVisibility)
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return isVisible ? (
    <div
      className={`scrolltotop ${isVisible ? "" : "d-none"}`}
      onClick={scrollToTop}
    >
      <i
        className={`icon-slider-left-arrow flex justify-center icon pr-2`}
        style={{ fontSize: "30px", color: 'white', transform: 'rotate(90deg)' }}
      />
      {/* <Icon
        iconName='icon-web_app_dev'
        className="flex justify-center "
        theme='white'
      /> */}
    </div>
  ) : null
}

export default ScrollToTopButton
